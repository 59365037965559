import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { ReactNode } from 'react';

export const ApiProvider = ({ client, children }: ApiProviderProps) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

interface ApiProviderProps {
  children: ReactNode;
  client: ApolloClient<NormalizedCacheObject>;
}
