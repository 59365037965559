import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type GelolocationState = 'denied' | 'prompt' | 'granted';

interface GeolocationPermissionsState {
  geolocationState: GelolocationState | null;
}

const initialState: GeolocationPermissionsState = {
  geolocationState: null,
};

export const geolocationPermissionsSlice = createSlice({
  name: 'geolocationPermissions',
  initialState,
  reducers: {
    setGeolocationState: (
      state,
      action: PayloadAction<GelolocationState | null>
    ) => {
      state.geolocationState = action.payload;
    },
  },
});

export const { setGeolocationState } = geolocationPermissionsSlice.actions;

export default geolocationPermissionsSlice.reducer;
