import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { GLOBAL_TOAST_MESSAGES } from '@/types/globalToastMessages';

import { GLOBAL_TOAST_MESSAGE_EVENT_NAME } from '../services/toastMessageEvent';

export const useGlobalToastMessages = () => {
  const { t } = useTranslation('errors');

  useEffect(() => {
    const showToastOnGraphQlError = (
      e: CustomEvent<{
        message: keyof typeof GLOBAL_TOAST_MESSAGES;
      }>
    ) => {
      toast.error(t(e.detail.message));
    };

    document.addEventListener(
      GLOBAL_TOAST_MESSAGE_EVENT_NAME,
      showToastOnGraphQlError
    );
    return () =>
      document.removeEventListener(
        GLOBAL_TOAST_MESSAGE_EVENT_NAME,
        showToastOnGraphQlError
      );
  }, [t]);
};
