/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { sup } from '@lib/sup/sup';
/**
 * Run it once on the client & server, to fail fast when env configuration
 * is broken
 */

type Environments = 'dev' | 'staging' | 'prod';
type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';

declare global {
  interface Window {
    __env__?: {
      NEXT_PUBLIC_API_GATEWAY_URI: string;
      NEXT_PUBLIC_WEBSITE_URL: string;
      NEXT_PUBLIC_FIREBASE_API_KEY: string;
      NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: string;
      NEXT_PUBLIC_GOOGLE_API_KEY: string;
      NEXT_PUBLIC_CT_STORE_KEY: string;
      NEXT_PUBLIC_SENTRY_CLIENT_DSN?: string;
      NEXT_PUBLIC_ENVIRONMENT: Environments;
      NEXT_PUBLIC_SEGMENT_WRITE_KEY?: string;
      NEXT_PUBLIC_GTAG_MEASUREMENT_ID?: string;
      NEXT_PUBLIC_LOG_LEVEL: LogLevel;
      NEXT_PUBLIC_DELIO_VERSION: string;
      NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT?: string;
      NEXT_PUBLIC_GTM_KEY?: string;
      NEXT_PUBLIC_GTM_CONTAINER_ID?: string;
    };
  }
}

const isServer = typeof window === 'undefined';

const validatePublicEnvs = () => {
  const [validationError, values] = schema.validate(
    {
      /**
       * `process.env` on the client side is not a standard object, it's a proxy.
       * We cannot destructure it nor use it as a `process.env`.
       * More info here: https://nextjs.org/docs/basic-features/environment-variables#loading-environment-variables
       * and here: https://github.com/vercel/next.js/blob/82001f2d543a95916cb9fa84a9b99e18dab3121d/packages/next/build/webpack-config.ts#L1349
       */

      NEXT_PUBLIC_API_GATEWAY_URI:
        (isServer
          ? process.env.NEXT_PUBLIC_API_GATEWAY_URI
          : window.__env__?.NEXT_PUBLIC_API_GATEWAY_URI) || '',
      NEXT_PUBLIC_WEBSITE_URL:
        (isServer
          ? process.env.NEXT_PUBLIC_WEBSITE_URL
          : window.__env__?.NEXT_PUBLIC_WEBSITE_URL) || '',
      NEXT_PUBLIC_GOOGLE_API_KEY:
        (isServer
          ? process.env.NEXT_PUBLIC_GOOGLE_API_KEY
          : window.__env__?.NEXT_PUBLIC_GOOGLE_API_KEY) || '',
      NEXT_PUBLIC_FIREBASE_API_KEY:
        (isServer
          ? process.env.NEXT_PUBLIC_FIREBASE_API_KEY
          : window.__env__?.NEXT_PUBLIC_FIREBASE_API_KEY) || '',
      NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
        (isServer
          ? process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
          : window.__env__?.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN) || '',
      NEXT_PUBLIC_CT_STORE_KEY:
        (isServer
          ? process.env.NEXT_PUBLIC_CT_STORE_KEY
          : window.__env__?.NEXT_PUBLIC_CT_STORE_KEY) || '',
      NEXT_PUBLIC_SENTRY_CLIENT_DSN:
        (isServer
          ? process.env.NEXT_PUBLIC_SENTRY_CLIENT_DSN
          : window.__env__?.NEXT_PUBLIC_SENTRY_CLIENT_DSN) || '',
      NEXT_PUBLIC_ENVIRONMENT:
        (isServer
          ? process.env.NEXT_PUBLIC_ENVIRONMENT
          : window.__env__?.NEXT_PUBLIC_ENVIRONMENT) || 'prod',
      NEXT_PUBLIC_LOG_LEVEL:
        (isServer
          ? process.env.NEXT_PUBLIC_LOG_LEVEL
          : window.__env__?.NEXT_PUBLIC_LOG_LEVEL) || 'debug',
      NEXT_PUBLIC_SEGMENT_WRITE_KEY: isServer
        ? process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
        : window.__env__?.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
      NEXT_PUBLIC_GTAG_MEASUREMENT_ID: isServer
        ? process.env.NEXT_PUBLIC_GTAG_MEASUREMENT_ID
        : window.__env__?.NEXT_PUBLIC_GTAG_MEASUREMENT_ID,
      // No need to manually set this env variable, see `next.config.json`
      NEXT_PUBLIC_DELIO_VERSION: process.env.NEXT_PUBLIC_DELIO_VERSION || '',
      NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT: isServer
        ? process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT
        : window.__env__?.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT,
      NEXT_PUBLIC_GTM_KEY: isServer
        ? process.env.NEXT_PUBLIC_GTM_KEY
        : window.__env__?.NEXT_PUBLIC_GTM_KEY,
      NEXT_PUBLIC_GTM_CONTAINER_ID: isServer
        ? process.env.NEXT_PUBLIC_GTM_CONTAINER_ID
        : window.__env__?.NEXT_PUBLIC_GTM_CONTAINER_ID,
    },
    {
      coerce: true,
    }
  );

  if (validationError) {
    throw new Error(
      `Invalid environment:\n${validationError
        .failures()
        .map((e) => `${e.path.join('')}: ${e.message}`)
        .join('\n')}`
    );
  }

  if (!values) {
    throw new Error('Cannot validate public env');
  }

  return values;
};

const schema = sup.type({
  NEXT_PUBLIC_API_GATEWAY_URI: sup.string(),
  NEXT_PUBLIC_WEBSITE_URL: sup.string(),
  NEXT_PUBLIC_FIREBASE_API_KEY: sup.string(),
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: sup.string(),
  NEXT_PUBLIC_GOOGLE_API_KEY: sup.string(),
  NEXT_PUBLIC_CT_STORE_KEY: sup.string(),
  NEXT_PUBLIC_SENTRY_CLIENT_DSN: sup.optional(sup.string()),
  NEXT_PUBLIC_ENVIRONMENT: sup.enums(['dev', 'staging', 'prod']),
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: sup.optional(sup.string()),
  NEXT_PUBLIC_GTAG_MEASUREMENT_ID: sup.optional(sup.string()),
  NEXT_PUBLIC_LOG_LEVEL: sup.enums([
    'trace' as const,
    'debug' as const,
    'info' as const,
    'warn' as const,
    'error' as const,
    'fatal' as const,
  ]),
  NEXT_PUBLIC_DELIO_VERSION: sup.string(),
  NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT: sup.optional(sup.string()),
  NEXT_PUBLIC_GTM_KEY: sup.optional(sup.string()),
  NEXT_PUBLIC_GTM_CONTAINER_ID: sup.optional(sup.string()),
});

/**
 * Envs that are available both on the client side and the server,
 * but there are no envs that are available on the server side only.
 * Type is explicit, so it fails just on runtime.
 */
export const publicEnv = validatePublicEnvs();
