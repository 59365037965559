import { publicEnv } from '@lib/env/public-env-vars';

/** Use it as a key for the api gateway client state in `pageProps` */
export const API_GW_CLIENT_PROP_NAME = 'API_GW_CLIENT_PROP_NAME';

/**
 * Custom header to prevent enforce CORS
 *
 * See: https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#use-of-custom-request-headers
 */
export const CSRF_PROTECTION_HEADER_NAME = 'X-CSRF-Protected';

/**
 * A name of the cookie that keeps an ID token.
 *
 * ID token is used for the authorized communication with the api gateway
 * service
 */
export const ID_TOKEN_COOKIE_NAME = 'idToken';

/**
 * A name of the cookie that keeps an refresh token.
 *
 * refresh token is used for the refresh session
 */
export const REFRESH_TOKEN_COOKIE_NAME = 'refreshToken';

/**
 * The time required for the session cookies to expire.
 */

const DAYS = 30;
const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTE = 60;
const MILLISECONDS_PER_SECOND = 1000;

export const SESSION_DURATION_MS =
  DAYS *
  HOURS_PER_DAY *
  MINUTES_PER_HOUR *
  SECONDS_PER_MINUTE *
  MILLISECONDS_PER_SECOND;

/** Use it as a key for the session state in `pageProps` */
export const SESSION_PROP_NAME = 'SESSION_STATE';

/** Name of the CT Api extension that describes the pin on the map */
export const CT_API_EXTENSION_ADDRESS_PIN = 'ct-api-extensions__address_pin';

/** Variable which identify GTM Workspace */
export const GTM_WORKSPACE_CONTAINER_ID = 'GTM-WG8V2QL';

/**
 * List of supported values of the CT API product tags.
 *
 * These tags comes from `productProjection.attributes.tags`
 */
export const PRODUCT_TAG_KEYS = [
  'vege',
  'bio',
  'glutenfree',
  'vegan',
  'eco',
  'sugarfree',
  'lactosefree',
  'new',
  'local',
  'health',
  'popular',
] as const;

/** An official e-mail address that our customer should use to contact with us */
export const DELIO_CONTACT_MAIL = 'hello@delio.com.pl';

/** We should include the header in all GQL API requests, both on the server and the client side */
export const API_VERSION_HEADERS = {
  'x-api-version': '4.0',
} as const;

/** A regular expression that we can use to remove disallowed characters from the phone number */
export const DISALLOWED_PHONE_NUMBERS_CHARACTERS_REG_EXP = /[^+0-9]/g;

/** delio's official website for driver recruitment */
export const DELIO_RIDERS_JOIN_WEBSITE = 'https://hello.delio.com.pl/join';

/** An official e-mail address that our customer should use to contact with personal data department */
export const PERSONAL_DATA_DEPARTMENT_MAIL = 'daneosobowe@delio.com.pl';

export const serverHeaders = {
  ...API_VERSION_HEADERS,
  'User-Agent': 'delio-web-service',
};

export const PRECONNECT_URLS = [
  'https://cdn.segment.com',
  'https://static.hotjar.com',
  'https://apis.google.com',
  'https://www.googleapis.com',
  `https://${publicEnv.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN}`,
] as const;

export const IMAGEKIT_URL = 'https://imgcdn.lite.tech';
