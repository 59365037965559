export const isFirebaseAuthError = (err: unknown): err is FirebaseAuthError => {
  if (typeof err !== 'object') return false;
  if (err === null) return false;
  const entries = Object.entries<unknown>(err as Record<string, unknown>);
  return entries.some(
    ([key, value]) => key === 'name' && value === 'FirebaseError'
  );
};

export type FirebaseAuthError = {
  code?: string;
  customData?: Record<string, unknown>;
  name: 'FirebaseError';
};
