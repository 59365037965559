import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PanelsKeys } from '@/modules/location/types';

interface LocationSchedulerState {
  activePanel: PanelsKeys;
  previousPanel: PanelsKeys;
  isAddressConfirmationVisible: boolean;
  isOpen: boolean;
}

const initialState: LocationSchedulerState = {
  activePanel: 'MainPanel',
  previousPanel: 'MainPanel',
  isAddressConfirmationVisible: false,
  isOpen: false,
};

export const locationSchedulerSlice = createSlice({
  name: 'locationScheduler',
  initialState,
  reducers: {
    openLocationSchedulerModal: (
      state,
      action: PayloadAction<LocationSchedulerState['activePanel'] | undefined>
    ) => {
      state.isOpen = true;
      if (action.payload) {
        state.activePanel = action.payload;
      }
    },
    closeLocationSchedulerModal: () => initialState,
    setActivePanel: (
      state,
      action: PayloadAction<LocationSchedulerState['activePanel']>
    ) => {
      state.previousPanel = state.activePanel;
      state.activePanel = action.payload;
    },
    setIsAddressConfirmationVisible: (
      state,
      action: PayloadAction<
        LocationSchedulerState['isAddressConfirmationVisible']
      >
    ) => {
      state.isAddressConfirmationVisible = action.payload;
    },
  },
});

export const {
  setActivePanel,
  setIsAddressConfirmationVisible,
  closeLocationSchedulerModal,
  openLocationSchedulerModal,
} = locationSchedulerSlice.actions;

export default locationSchedulerSlice.reducer;
