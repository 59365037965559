import { addEventProcessor, captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

import { analytics } from '@/config/analytics';
import { publicEnv } from '@lib/env/public-env-vars';

export const useSegmentAnalytics = ({
  isUserLoggedIn,
}: {
  isUserLoggedIn: boolean;
}) => {
  useEffect(() => {
    if (isUserLoggedIn) {
      analytics
        .load({
          writeKey: String(publicEnv.NEXT_PUBLIC_SEGMENT_WRITE_KEY),
        })
        .catch((err) => captureException(err));

      registerHotJarHandler();
    }
  }, [isUserLoggedIn]);
};
const registerHotJarHandler = () => {
  addEventProcessor((event) => {
    const parsedErrorMessage = (
      event.exception?.values?.map(
        (e) => `${e.type ?? 'Error'} ${e.value ?? ''}`
      ) ?? []
    ).join(' > ');

    analytics.track(
      'Error occured',
      {
        parsedError: parsedErrorMessage || 'Unknown error',
        eventId: event.event_id,
      },
      {
        integrations: {
          All: false,
          Hotjar: true,
        },
      }
    );
    return event;
  });
};
