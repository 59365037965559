import { GLOBAL_TOAST_MESSAGES } from '@/types/globalToastMessages';

export const GLOBAL_TOAST_MESSAGE_EVENT_NAME = 'GLOBAL_TOAST_MESSAGE_EVENT';

export const dispatchGlobalToastMessage = (
  message: keyof typeof GLOBAL_TOAST_MESSAGES
) => {
  document.dispatchEvent(
    new CustomEvent(GLOBAL_TOAST_MESSAGE_EVENT_NAME, {
      detail: {
        message,
      },
    })
  );
};
