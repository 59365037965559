import { publicEnv } from '@lib/env/public-env-vars';

export const createReactGa = async () => {
  const { default: ReactGa } = await import('react-ga4');

  if (!ReactGa.isInitialized && publicEnv.NEXT_PUBLIC_GTAG_MEASUREMENT_ID) {
    ReactGa.initialize(publicEnv.NEXT_PUBLIC_GTAG_MEASUREMENT_ID);
  }

  return ReactGa;
};
