import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { analytics } from '@/config/analytics';

import { createReactGa } from '../factories/createReactGa';

export const TrackPageEvent = () => {
  const router = useRouter();
  const sendPageView = useSendPageView();
  useEffect(() => {
    const sendCurrentPageView = () => sendPageView(router.asPath);
    sendCurrentPageView();
  }, [router.asPath, sendPageView]);

  return null;
};

const useSendPageView = () => async (path: string) => {
  const ga = await createReactGa();

  ga.send({ hitType: 'pageview', page: path });
  analytics.page(
    undefined,
    path,
    {},
    {
      integrations: {
        All: true,
        'Google Analytics 4': false,
      },
    }
  );
};
