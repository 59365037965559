import type { ParsedUrlQuery } from 'querystring';

export const PAGE_SEARCH_PARAM_NAME = 'p';
export const FIRST_PAGE = 1;
export const MAX_PAGE = 200;

/**
 * Grabs the current page number from the query, then it either uses the parsed
 * value or uses a fallback, when the value is invalid.
 */
export const getCurrentPage = (query: ParsedUrlQuery) => {
  const currentPageFromParam = query[PAGE_SEARCH_PARAM_NAME];

  const currentPage = Math.max(
    parseBaseTenInt(String(currentPageFromParam), FIRST_PAGE),
    FIRST_PAGE
  );

  return currentPage > MAX_PAGE ? FIRST_PAGE : currentPage;
};

const parseBaseTenInt = (
  input: string | null | undefined,
  fallback: number
) => {
  const parsedInt = parseInt(input ?? '', 10);
  return Number.isNaN(parsedInt) ? fallback : parsedInt;
};
