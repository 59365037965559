import { setUser } from '@sentry/nextjs';
import { ReactNode, useEffect, useMemo } from 'react';

import { analytics } from '@/config/analytics';
import { logger } from '@/config/logger';
import { FCC } from '@/types/common';
import { createFirebaseAuthClient } from '@lib/firebase-auth-client/firebase-auth-client';

import { AuthContext } from '../contexts/AuthContext';
import { useSessionManagement } from '../hooks/useSessionManagement';
import { selectAuthRole } from '../selectors/selectAuthRole';
import { Session } from '../types';

export const AuthProvider: FCC<AuthProviderProps> = ({
  children,
  firebaseAuthClient,
  session,
  onSessionDestroyed,
}) => {
  const {
    createSessionState,
    createSession,
    destroySessionState,
    destroySession,
    confirmUser,
  } = useSessionManagement({
    session,
    onSessionDestroyed,
  });
  const authRole = selectAuthRole(createSessionState.session);

  useSetSentryUser(session ?? createSessionState.session);
  useSetSegmentUser(session ?? createSessionState.session);

  const authContextProps = useMemo(
    () => ({
      firebaseAuthClient,
      createSessionState,
      createSession,
      destroySessionState,
      destroySession,
      authRole,
      confirmUser,
    }),
    [
      authRole,
      createSession,
      createSessionState,
      destroySession,
      destroySessionState,
      firebaseAuthClient,
      confirmUser,
    ]
  );

  return (
    <AuthContext.Provider value={authContextProps}>
      {children}
    </AuthContext.Provider>
  );
};

const useSetSentryUser = (session: Session) => {
  const id = session?.data.id;

  useEffect(() => {
    if (typeof id !== 'string') return;
    if (id.length === 0) {
      logger.error('Cannot set user id in sentry due to missing session.id');
      return;
    }

    setUser({
      id,
    });
  }, [id]);
};
const useSetSegmentUser = (session: Session) => {
  const { id, attributes } = session?.data ?? {};
  useEffect(() => {
    if (typeof id !== 'string') return;
    if (id.length === 0) {
      logger.error('Cannot set user id in sentry due to missing session.id');
      return;
    }

    if (attributes?.userProfile.isAnonymous) return;
    analytics?.identify?.(id, {
      email: attributes?.userProfile?.email,
    });

    return () => {
      analytics?.reset?.();
    };
  }, [id, attributes]);
};

export interface AuthProviderProps {
  children: ReactNode;
  firebaseAuthClient: ReturnType<typeof createFirebaseAuthClient>;
  session: Session;
  onSessionDestroyed?: () => void;
  onSessionCreated?: () => void;
}
