import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

import { logger } from '@/config/logger';

export const useGetAuthErrorTranslation = () => {
  const { t } = useTranslation('auth');

  return useCallback(
    (authError: string) => {
      switch (authError) {
        case 'auth/credential-already-in-use':
        case 'auth/email-already-in-use':
        case 'auth/account-exists-with-different-credential':
          return t("It seems that you've an account already");

        case 'auth/user-not-found':
        case 'auth/invalid-email':
          return t('Invalid email address');

        case 'auth/invalid-action-code':
          return t(
            "Something went wrong, please make sure you're using a correct link"
          );

        case 'auth/popup-closed-by-user': {
          return t(
            'The authentication popup has been closed to early, try again'
          );
        }

        case 'auth/user-cancelled': {
          return t('The authentication process has been cancelled');
        }

        case 'auth/popup-blocked': {
          return t('Allow for pop-up windows when signing in or signing up');
        }

        default:
          logger.error(`Unknown auth error ${authError}`);
          return t('Something went wrong, please try again later', {
            ns: 'common',
          });
      }
    },
    [t]
  );
};
