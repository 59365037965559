import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { mergeDeep } from '@apollo/client/utilities';

export const createApiClient = ({
  uri,
  ssrState = null,
  errorHandler,
  headers,
  cache,
  refreshToken,
  shouldIncludeCredentials = true,
}: CreateApiClientOptions): ApolloClient<NormalizedCacheObject> => {
  const apolloClientLocal = createApolloClient({
    uri,
    errorHandler,
    headers,
    cache,
    refreshToken,
    shouldIncludeCredentials,
  });
  return restoreApolloClient(apolloClientLocal, ssrState);
};

export const restoreApolloClient = (
  apolloClientLocal: ApolloClient<NormalizedCacheObject>,
  ssrState: NormalizedCacheObject | null
) => {
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (ssrState) {
    // Get existing cache, loaded during client side data fetching
    const clientSideState = apolloClientLocal.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const hydratedState = mergeDeep(ssrState, clientSideState);

    // Restore the cache with the merged data
    apolloClientLocal.cache.restore(hydratedState);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return apolloClientLocal;

  return apolloClientLocal;
};

const createApolloClient = ({
  uri,
  errorHandler,
  headers,
  cache,
  refreshToken,
  shouldIncludeCredentials,
}: {
  uri: string;
  errorHandler: ErrorHandler;
  headers: Record<string, string>;
  cache: InMemoryCache;
  refreshToken?: ApolloLink;
  shouldIncludeCredentials: boolean;
}) => {
  const config = {
    ...(shouldIncludeCredentials && { credentials: 'include' }),
    headers,
  };

  const delioHttpLink = new HttpLink({ uri: `${uri}/delio`, ...config });
  const commonHttpLink = new HttpLink({ uri: `${uri}/onebrand`, ...config });

  const errorLink = onError(errorHandler);

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: unknown) =>
        key === '__typename' ? undefined : value;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
    }
    return forward(operation).map((data) => data);
  });

  const linkConfig = [
    errorLink, // errorLink should be the first one
    cleanTypeName,
    ...(refreshToken ? [refreshToken] : []),
  ];

  const delioLink = from([...linkConfig, delioHttpLink]);
  const commonLink = from([...linkConfig, commonHttpLink]);

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === 'common',
      commonLink,
      delioLink
    ),
    cache,
  });
};

export interface CreateApiClientOptions {
  uri: string;
  ssrState: NormalizedCacheObject | null;
  /**
   * An error handler that goes to an error link in ApolloClient
   *
   * More details:
   * - https://www.apollographql.com/docs/react/data/error-handling/
   * - https://www.apollographql.com/docs/react/api/link/apollo-link-error/
   */
  errorHandler: ErrorHandler;
  headers: Record<string, string>;
  cache: InMemoryCache;
  refreshToken?: ApolloLink;
  shouldIncludeCredentials?: boolean;
}
