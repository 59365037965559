// based on design from
// https://www.figma.com/file/3EaTy3ZAoxB7nFSLbRHKHJ/%5BD-Web%5D-Components?type=design&node-id=2037-42354&t=7nwAkVQ5Smr0ynlQ-0
const MAX_VISIBLE_PAGES_MOBILE = 5;
const MAX_VISIBLE_PAGES_DESKTOP = 7;

const FIRST_PAGE = 1;
const SECOND_PAGE = 2;
const THIRD_PAGE = 3;
const FOURTH_PAGE = 4;
const FIFTH_PAGE = 5;

export const ELLIPSIS = '...';

export const createPagesList = (
  currentPage: number,
  maxPage: number,
  isMobile: boolean
): Array<number | string> => {
  const MAX_VISIBLE_PAGES = isMobile
    ? MAX_VISIBLE_PAGES_MOBILE
    : MAX_VISIBLE_PAGES_DESKTOP;

  if (maxPage <= MAX_VISIBLE_PAGES) return createAllPagesList(maxPage);

  currentPage = Math.min(currentPage, maxPage);

  if (currentPage <= THIRD_PAGE) {
    return isMobile
      ? [FIRST_PAGE, SECOND_PAGE, THIRD_PAGE, ELLIPSIS, maxPage]
      : [
          FIRST_PAGE,
          SECOND_PAGE,
          THIRD_PAGE,
          FOURTH_PAGE,
          FIFTH_PAGE,
          ELLIPSIS,
          maxPage,
        ];
  }

  if (currentPage >= maxPage - SECOND_PAGE) {
    return isMobile
      ? [
          FIRST_PAGE,
          ELLIPSIS,
          maxPage - SECOND_PAGE,
          maxPage - FIRST_PAGE,
          maxPage,
        ]
      : [
          FIRST_PAGE,
          ELLIPSIS,
          maxPage - FOURTH_PAGE,
          maxPage - THIRD_PAGE,
          maxPage - SECOND_PAGE,
          maxPage - FIRST_PAGE,
          maxPage,
        ];
  }

  return isMobile
    ? [FIRST_PAGE, ELLIPSIS, currentPage, ELLIPSIS, maxPage]
    : [
        FIRST_PAGE,
        ELLIPSIS,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        ELLIPSIS,
        maxPage,
      ];
};

const createAllPagesList = (maxPage: number): Array<number> =>
  Array.from({ length: maxPage }, (_, i) => i + FIRST_PAGE);

export type CreatePagesList = (
  currentPage: number,
  maxPage: number,
  isMobile: boolean
) => Array<number | string>;

export type PagesList = Array<number | string>;
