import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { CategoryLandingSliderDesktopProps } from '@/modules/categoryLanding/components/Header/components/CategoryLandingSliderDesktop/CategoryLandingSliderDesktop.types';

interface DropdownState {
  dropdown: {
    left: number;
    bottom: number;
  };
  category: CategoryLandingSliderDesktopProps | null;
}

const initialState: DropdownState = {
  dropdown: {
    left: 0,
    bottom: 0,
  },
  category: null,
};

export const landingSliderDropdownSlice = createSlice({
  name: 'dropdowns',
  initialState,
  reducers: {
    setLandingSliderDropdownPosition: (
      state,
      action: PayloadAction<DropdownState['dropdown']>
    ) => {
      state.dropdown = {
        ...action.payload,
        bottom: action.payload.bottom,
      };
    },
    setLandingSliderDropdownChildren: (
      state,
      action: PayloadAction<DropdownState['category']>
    ) => {
      state.category = action.payload;
    },
  },
});

export const {
  setLandingSliderDropdownPosition,
  setLandingSliderDropdownChildren,
} = landingSliderDropdownSlice.actions;

export default landingSliderDropdownSlice.reducer;
