import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useSWR from 'swr';

import { usePrevious } from './usePrevious';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const useVersionCheck = () => {
  const router = useRouter();
  const { data } = useSWR<{ buildId: string }>('/api/build-id', fetcher, {
    revalidateOnFocus: true,
  });
  const buildId = data?.buildId;
  const prevBuildId = usePrevious(buildId);

  useEffect(() => {
    if (prevBuildId && buildId && prevBuildId !== buildId) {
      router.reload();
    }
  }, [buildId, prevBuildId, router]);
};
