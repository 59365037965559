/**
 * It performs a simple check for a falsy value, so we can exclude falsy
 * (https://developer.mozilla.org/en-US/docs/Glossary/Falsy) types from the
 * value.
 *
 * **Be aware that this function also excludes `NaN`, but we cannot exclude
 * `NaN` from the type, since its type is `number`.**
 */
export const isTruthy = <T>(
  value: T
  // eslint-disable-next-line no-magic-numbers
): value is Exclude<T, false | 0 | '' | null | undefined | 0n> =>
  Boolean(value);
