// @ts-check
const path = require('path');

const localePath =
  process.env.NODE_ENV !== 'production' ? './src/locales' : '/app/locales';

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    defaultLocale: 'pl',
    locales: ['pl'],
  },
  keySeparator: false,
  nsSeparator: false,
  contextSeparator: '_',
  returnNull: false,
  localePath: path.resolve(localePath),
  reloadOnPrerender: process.env.NODE_ENV !== 'production',
  debug: false,
  react: {
    // ReactDOM does not support suspense with SSR
    // https://github.com/isaachinman/next-i18next/issues/1255
    useSuspense: false,
  },
};
