import * as Types from '@/types/gqlCommon.common.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"common"}} as const;
export type UpdateCustomerShippingAddressMutationVariables = Types.Exact<{
  updateCustomerShippingAddressId: Types.Scalars['ID']['input'];
  shippingAddress: Types.ShippingAddressInput;
}>;


export type UpdateCustomerShippingAddressMutation = { readonly __typename: 'Mutation', readonly updateCustomerShippingAddress: { readonly __typename: 'CustomerShippingAddress', readonly id: string, readonly shippingAddress: { readonly __typename: 'ShippingAddress', readonly firstName?: string | null, readonly lastName?: string | null, readonly streetName?: string | null, readonly streetNumber: string, readonly postalCode: string, readonly city: string, readonly countryCode: string, readonly apartment?: string | null, readonly lat: number, readonly long: number, readonly floor?: string | null, readonly deliveryNotes?: string | null, readonly phoneNumber?: { readonly __typename: 'PhoneNumber', readonly nationalNumber: string, readonly countryCode: string } | null } } };


export const UpdateCustomerShippingAddressDocument = gql`
    mutation UpdateCustomerShippingAddress($updateCustomerShippingAddressId: ID!, $shippingAddress: ShippingAddressInput!) {
  updateCustomerShippingAddress(
    id: $updateCustomerShippingAddressId
    shippingAddress: $shippingAddress
  ) {
    id
    shippingAddress {
      firstName
      lastName
      phoneNumber {
        nationalNumber
        countryCode
      }
      streetName
      streetNumber
      postalCode
      city
      countryCode
      apartment
      lat
      long
      floor
      deliveryNotes
    }
  }
}
    `;
export type UpdateCustomerShippingAddressMutationFn = Apollo.MutationFunction<UpdateCustomerShippingAddressMutation, UpdateCustomerShippingAddressMutationVariables>;

/**
 * __useUpdateCustomerShippingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerShippingAddressMutation, { data, loading, error }] = useUpdateCustomerShippingAddressMutation({
 *   variables: {
 *      updateCustomerShippingAddressId: // value for 'updateCustomerShippingAddressId'
 *      shippingAddress: // value for 'shippingAddress'
 *   },
 * });
 */
export function useUpdateCustomerShippingAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerShippingAddressMutation, UpdateCustomerShippingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerShippingAddressMutation, UpdateCustomerShippingAddressMutationVariables>(UpdateCustomerShippingAddressDocument, options);
      }
export type UpdateCustomerShippingAddressMutationHookResult = ReturnType<typeof useUpdateCustomerShippingAddressMutation>;
export type UpdateCustomerShippingAddressMutationResult = Apollo.MutationResult<UpdateCustomerShippingAddressMutation>;
export type UpdateCustomerShippingAddressMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerShippingAddressMutation, UpdateCustomerShippingAddressMutationVariables>;