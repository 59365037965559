import {
  Auth,
  signInAnonymously as firebaSesignInAnonymously,
  signInWithCustomToken as signInWithToken,
  User,
} from 'firebase/auth';

const parseUserData = (user: User) => {
  const { refreshToken, displayName, email } = user;
  const [firstName, lastName] = displayName?.split(' ') || [];

  return {
    refreshToken,
    userProfile: { firstName, lastName, email },
    sessionSource:
      user.metadata.creationTime === user.metadata.lastSignInTime
        ? ('sign-up' as const)
        : ('sign-in' as const),
  };
};

export const signInWithCustomToken = async (
  auth: Auth,
  customToken: string
) => {
  const { user } = await withNonPersistentAuth(
    auth,
    signInWithToken(auth, customToken)
  );
  return parseUserData(user);
};

export const signInAnonymously = async (auth: Auth) => {
  const { user } = await firebaSesignInAnonymously(auth);

  return parseUserData(user);
};

/**
 * It performs an action in the impermanent manner, meaning the user data
 * (including tokens) is cleared after the action has been resolved or rejected.
 *
 * It should wrap all authentication actions with Firebase, so that all user
 * data is removed from memory as quick as possible. We don't need session
 * persistence in the Firebase client SDK, because we use custom session
 * cookies.
 */
const withNonPersistentAuth = async <T>(
  auth: Auth,
  authActionPromise: Promise<T>
) => {
  try {
    const result = await authActionPromise;
    await auth.signOut();
    return result;
  } catch (err) {
    await auth.signOut();
    throw err;
  }
};
