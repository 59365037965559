import * as Types from '@/types/gqlCommon.delio.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebPageMetadataQueryVariables = Types.Exact<{
  path: Types.Scalars['String']['input'];
}>;


export type WebPageMetadataQuery = { readonly __typename: 'Query', readonly webPageMetadata?: { readonly __typename: 'WebPageMetadata', readonly path: string, readonly title: string, readonly description: string, readonly twitterImageUrl?: string | null, readonly ogImageUrl?: string | null } | null };


export const WebPageMetadataDocument = gql`
    query WebPageMetadata($path: String!) {
  webPageMetadata(path: $path) {
    path
    title
    description
    twitterImageUrl
    ogImageUrl
  }
}
    `;

/**
 * __useWebPageMetadataQuery__
 *
 * To run a query within a React component, call `useWebPageMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebPageMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebPageMetadataQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useWebPageMetadataQuery(baseOptions: Apollo.QueryHookOptions<WebPageMetadataQuery, WebPageMetadataQueryVariables> & ({ variables: WebPageMetadataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebPageMetadataQuery, WebPageMetadataQueryVariables>(WebPageMetadataDocument, options);
      }
export function useWebPageMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebPageMetadataQuery, WebPageMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebPageMetadataQuery, WebPageMetadataQueryVariables>(WebPageMetadataDocument, options);
        }
export function useWebPageMetadataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebPageMetadataQuery, WebPageMetadataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebPageMetadataQuery, WebPageMetadataQueryVariables>(WebPageMetadataDocument, options);
        }
export type WebPageMetadataQueryHookResult = ReturnType<typeof useWebPageMetadataQuery>;
export type WebPageMetadataLazyQueryHookResult = ReturnType<typeof useWebPageMetadataLazyQuery>;
export type WebPageMetadataSuspenseQueryHookResult = ReturnType<typeof useWebPageMetadataSuspenseQuery>;
export type WebPageMetadataQueryResult = Apollo.QueryResult<WebPageMetadataQuery, WebPageMetadataQueryVariables>;