import { captureException } from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { logger } from '@/config/logger';
import { useMergeAnonymousCustomer } from '@/modules/auth/hooks/useMergeAnonymousCustomer';
import { loadFreshdesk } from '@/modules/freshdesk/services/loadFreshdesk';
import { HTTP_STATUS_CODE } from '@lib/api/types';
import { isFirebaseAuthError } from '@lib/firebase-auth-client/is-firebase-auth-error';

import * as sessionRequests from '../client/sessionRequests';
import { ClientAuthorizationData, Session } from '../types';

import { useGetAuthErrorTranslation } from './useGetAuthErrorTranslations';

export const useSessionManagement = ({
  session,
  onSessionDestroyed,
}: {
  session: Session;
  onSessionDestroyed?: () => Promise<void> | void;
}) => {
  // session null means that the session was checked in get server side props and is invalid
  // session undefined means that the session does not exist, as it is probably a statically generated page (for example, about-us).
  const wasSessionVerificationStartedRef = useRef(session || session === null);
  const anonymousSessionRef = useRef<Session>(null);
  const getAuthErrorTranslation = useGetAuthErrorTranslation();
  const mergeAnonymousCustomer = useMergeAnonymousCustomer();
  const { t } = useTranslation('auth');
  const [isVerifyingSession, setIsVerifyingSession] = useState(
    session === undefined
  );

  const [createSessionState, setCreateSessionState] =
    useState<CreateSessionState>(
      session
        ? {
            status: 'resolved',
            session,
            error: null,
          }
        : {
            status: 'idle',
            session: null,
            error: null,
          }
    );

  const [destroySessionState, setDestroySessionState] =
    useState<DestroySessionState>({
      status: 'idle',
      error: null,
    });

  const createSession = useCallback<CreateSession>(
    async ({
      refreshTokenPromise,
      isPhoneNumberSignUpRequired,
      shouldSkipMerge,
    }) => {
      const prevSession = createSessionState.session;

      if (prevSession && prevSession.data.attributes.userProfile.isAnonymous) {
        // Keep reference to the anonymous session in case we need to merge
        // the anonymous customer to the signed in customer
        anonymousSessionRef.current = createSessionState.session;
      }

      const anonymousUserId = anonymousSessionRef.current?.data.id;

      setCreateSessionState({
        status: 'loading',
        // Keep anonymous session to avoid UI flickering
        session: anonymousSessionRef.current ?? null,
        error: null,
      });

      setDestroySessionState({
        status: 'idle',
        error: null,
      });

      try {
        const userDetails = await refreshTokenPromise;

        const createSessionResponse = await sessionRequests.createSession(
          userDetails.refreshToken
        );

        const { isAnonymous, isConfirmed } =
          createSessionResponse.data.data.attributes.userProfile;
        if (userDetails.sessionSource === 'sign-up') {
          // User was not confirmed, we need to reload the session
          // Otherwise, they can get stale ID token and API throws with UNAUTHORIZED
          const sessionCreatedAfterConfirmation =
            await sessionRequests.createSession(userDetails.refreshToken);
          const session = sessionCreatedAfterConfirmation.data;
          session.data.attributes.userProfile = {
            ...session.data.attributes.userProfile,
            firstName: userDetails.userProfile.firstName,
            lastName: userDetails.userProfile.lastName,
          };

          setCreateSessionState({
            status: 'resolved',
            session,
            error: null,
          });

          return { ...userDetails, isConfirmed };
        }

        const session = createSessionResponse.data;
        if (
          anonymousUserId &&
          !shouldSkipMerge &&
          !isPhoneNumberSignUpRequired
        ) {
          await mergeAnonymousCustomer(anonymousUserId);
        }

        setCreateSessionState({
          status: 'resolved',
          session,
          error: null,
        });

        if (!isAnonymous && !isPhoneNumberSignUpRequired) {
          toast.success(t('Welcome back'));
        }

        loadFreshdesk(session);

        return { ...userDetails, isConfirmed };
      } catch (err: unknown) {
        captureException(err, {
          extra: { logic: 'createSession' },
        });
        const isAxiosError = await getIsAxiosError();

        const authErrorMessage =
          isFirebaseAuthError(err) && err.code
            ? getAuthErrorTranslation(err.code)
            : null;

        if (!authErrorMessage && !isAxiosError(err)) logger.error(err);

        const errorMessage =
          authErrorMessage ??
          t('Something went wrong, please try again later', {
            ns: 'common',
          });

        setCreateSessionState({
          status: 'error',
          // Keep the anonymous session even after unsuccessful sign in try,
          // otherwise the user will lost the data
          session: anonymousSessionRef.current ?? null,
          error: isAxiosError(err) ? err.message : 'Unknown error',
        });

        toast.error(errorMessage);

        throw err;
      }
    },
    [
      createSessionState.session,
      t,
      mergeAnonymousCustomer,
      getAuthErrorTranslation,
    ]
  );

  const confirmUser = useCallback(
    async (userDetails: Omit<ClientAuthorizationData, 'sessionSource'>) => {
      const anonymousUserId = anonymousSessionRef.current?.data.id;

      // User was not confirmed, we need to reload the session
      // Otherwise, they can get stale ID token and API throws with UNAUTHORIZED
      const sessionCreatedAfterConfirmation =
        await sessionRequests.createSession(userDetails.refreshToken);

      const session = sessionCreatedAfterConfirmation.data;

      if (anonymousUserId) {
        await mergeAnonymousCustomer(anonymousUserId);
      }

      setCreateSessionState({
        status: 'resolved',
        session,
        error: null,
      });

      return userDetails;
    },
    [mergeAnonymousCustomer]
  );

  const destroySession = useCallback<DestroySession>(async () => {
    setDestroySessionState({
      status: 'loading',
      error: null,
    });

    try {
      toast.success(t('Thank you for using delio'));

      await sessionRequests.destroySession();

      setDestroySessionState({
        status: 'resolved',
        error: null,
      });

      setCreateSessionState({
        status: 'idle',
        session: null,
        error: null,
      });

      anonymousSessionRef.current = null;

      await onSessionDestroyed?.();
    } catch (err: unknown) {
      captureException(err, {
        extra: { logic: 'destroySession' },
      });
      const isAxiosError = await getIsAxiosError();

      toast.error(
        t('Something went wrong, please try again later', {
          ns: 'common',
        })
      );

      return setDestroySessionState({
        status: 'error',
        error: isAxiosError(err) ? err.message : 'Unknown error',
      });
    }
  }, [onSessionDestroyed, t]);

  useEffect(() => {
    if (session || wasSessionVerificationStartedRef.current) return;
    // The session is validated on ssr. The code below will never be called on the ssr page.
    // It still needs to be present because we need to validate the client-side session on a statically generated page. For example, about-us.
    const verifySession = async () => {
      setIsVerifyingSession(true);
      wasSessionVerificationStartedRef.current = true;

      try {
        const { data: session } = await sessionRequests.refreshSession();
        setCreateSessionState({
          session,
          status: 'resolved',
          error: null,
        });
      } catch (err) {
        const isAxiosError = await getIsAxiosError();

        logger.info(
          'Cannot verify session, continuing as unauthenticated user...'
        );

        if (
          isAxiosError(err) &&
          err.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return;
        }

        captureException(err, {
          extra: { logic: 'verifySession' },
        });
        logger.error(err);
      } finally {
        setIsVerifyingSession(false);
      }
    };

    verifySession();
  }, [session]);

  return {
    isVerifyingSession,
    wasSessionVerified: wasSessionVerificationStartedRef.current,
    createSession,
    createSessionState,
    destroySession,
    destroySessionState,
    confirmUser,
  };
};

export type CreateSessionState =
  | {
      status: 'idle';
      session: null;
      error: null;
    }
  | {
      status: 'loading';
      session: Session;
      error: null;
    }
  | {
      status: 'resolved';
      session: Session;
      error: null;
    }
  | {
      status: 'error';
      session: Session;
      error: string;
    };

export type DestroySessionState = {
  status: 'idle' | 'loading' | 'resolved' | 'error';
  error: null | string;
};

type SessionArgs = {
  refreshTokenPromise: Promise<ClientAuthorizationData>;
  isPhoneNumberSignUpRequired?: boolean;
  shouldSkipMerge?: boolean;
};
export type CreateSession = (
  args: SessionArgs
) => Promise<ClientAuthorizationData & { isConfirmed: boolean }>;

export type DestroySession = () => Promise<void>;

const getIsAxiosError = async () => {
  const { default: axios } = await import('axios');
  return axios.isAxiosError.bind(axios);
};
