import { CurrentCartQueryResult } from '@/core/graphql/queries/CurrentCart/CurrentCart.delio.generated';

export const DEFAULT_ADDRESS_COUNTRY_CODE = 'PL';

export type TextAndImageSection = {
  readonly __typename?: 'TextAndImageSection';
  readonly id: string;
  readonly textRaw: unknown;
  readonly title: string;
  readonly webImage: {
    readonly __typename?: 'TextAndImageSectionWebImage';
    readonly desktopImageUrl: string;
  };
};

export type CurrentCart = NonNullable<
  NonNullable<CurrentCartQueryResult['data']>['currentCart']
>;

export type LineItem = NonNullable<CurrentCart['lineItems'][number]>;
