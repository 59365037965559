import { ErrorHandler } from '@apollo/client/link/error';
import { GraphQLError, GraphQLFormattedError } from 'graphql';

import { dispatchGlobalToastMessage } from '@/core/services/toastMessageEvent';
import { createRefreshTokenObservable } from '@/modules/auth/client/refreshTokenObservable';
import { AUTH_ERROR_CODE } from '@/modules/auth/consts';

export const clientSideErrorHandler: ErrorHandler = (err) => {
  const { graphQLErrors, forward, operation } = err;

  const unauthenticatedError = graphQLErrors?.find(isUnauthenticatedError);
  const paymentPendingError = graphQLErrors?.find(isPaymentPendingError);

  if (paymentPendingError) {
    dispatchGlobalToastMessage(
      'Oops! Something went wrong. Wait a few minutes and try again.'
    );

    return forward(operation);
  }

  // Refresh the token and retry the request
  if (unauthenticatedError) {
    const refreshObservable = createRefreshTokenObservable();
    return refreshObservable.flatMap(() => forward(operation));
  }

  const concurrentModificationError = graphQLErrors?.find(
    isConcurrentModificationError
  );

  // Retry the mutation with the currentVersion
  if (concurrentModificationError) {
    operation.variables.version =
      concurrentModificationError.extensions.currentVersion;

    return forward(operation);
  }
};

const isUnauthenticatedError = (value: GraphQLFormattedError) =>
  value?.extensions?.code === AUTH_ERROR_CODE.UNAUTHENTICATED;

const isPaymentPendingError = (value: GraphQLFormattedError) =>
  value?.extensions?.code === AUTH_ERROR_CODE.PAYMENT_PENDING;

const isConcurrentModificationError = (
  value: GraphQLFormattedError
): value is ConcurrentModificationError =>
  value?.extensions?.code === 'ConcurrentModification';

type ConcurrentModificationError = GraphQLError & {
  extensions: ConcurrentModificationExtensions;
};

type ConcurrentModificationExtensions = {
  code: 'ConcurrentModification';
  currentVersion: number;
};
