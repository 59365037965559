import * as Types from '@/types/gqlCommon.common.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"common"}} as const;
export type CreateCustomerShippingAddressMutationVariables = Types.Exact<{
  shippingAddress: Types.ShippingAddressInput;
}>;


export type CreateCustomerShippingAddressMutation = { readonly __typename: 'Mutation', readonly createCustomerShippingAddress: { readonly __typename: 'CustomerShippingAddress', readonly id: string, readonly shippingAddress: { readonly __typename: 'ShippingAddress', readonly firstName?: string | null, readonly lastName?: string | null, readonly streetName?: string | null, readonly streetNumber: string, readonly postalCode: string, readonly city: string, readonly countryCode: string, readonly apartment?: string | null, readonly lat: number, readonly long: number, readonly floor?: string | null, readonly deliveryNotes?: string | null, readonly phoneNumber?: { readonly __typename: 'PhoneNumber', readonly nationalNumber: string, readonly countryCode: string } | null } } };


export const CreateCustomerShippingAddressDocument = gql`
    mutation CreateCustomerShippingAddress($shippingAddress: ShippingAddressInput!) {
  createCustomerShippingAddress(shippingAddress: $shippingAddress) {
    id
    shippingAddress {
      firstName
      lastName
      phoneNumber {
        nationalNumber
        countryCode
      }
      streetName
      streetNumber
      postalCode
      city
      countryCode
      apartment
      lat
      long
      floor
      deliveryNotes
    }
  }
}
    `;
export type CreateCustomerShippingAddressMutationFn = Apollo.MutationFunction<CreateCustomerShippingAddressMutation, CreateCustomerShippingAddressMutationVariables>;

/**
 * __useCreateCustomerShippingAddressMutation__
 *
 * To run a mutation, you first call `useCreateCustomerShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerShippingAddressMutation, { data, loading, error }] = useCreateCustomerShippingAddressMutation({
 *   variables: {
 *      shippingAddress: // value for 'shippingAddress'
 *   },
 * });
 */
export function useCreateCustomerShippingAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerShippingAddressMutation, CreateCustomerShippingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerShippingAddressMutation, CreateCustomerShippingAddressMutationVariables>(CreateCustomerShippingAddressDocument, options);
      }
export type CreateCustomerShippingAddressMutationHookResult = ReturnType<typeof useCreateCustomerShippingAddressMutation>;
export type CreateCustomerShippingAddressMutationResult = Apollo.MutationResult<CreateCustomerShippingAddressMutation>;
export type CreateCustomerShippingAddressMutationOptions = Apollo.BaseMutationOptions<CreateCustomerShippingAddressMutation, CreateCustomerShippingAddressMutationVariables>;