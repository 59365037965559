export enum CHECKOUT_STEP {
  DELIVERY = 1,
  BILLING_ADDRESS,
  PAYMENT,
}
export type PaymentMethod = 'card' | 'blik';

export type ProductViewedPayload = {
  sku: string;
  version: string;
  attribution_token?: string;
};

export type ProductOosPayload = {
  product_id: string;
  sku?: string | null;
  name?: string | null;
  price: number;
  currency: string;
  url: string;
  image_url?: string | null;
  version: string;
};
