import { Observable } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import type { AxiosResponse } from 'axios';

import { SessionDocument } from '../types';

import { refreshSession } from './sessionRequests';

let refreshPromise: Promise<AxiosResponse<SessionDocument>> | null = null;

export const createRefreshTokenObservable = () =>
  new Observable((observer) => {
    // Call API only if the refresh request is not pending already,
    // otherwise, just wait for the existing promise to be resolved.
    if (!refreshPromise) {
      refreshPromise = refreshSession();
    }

    refreshPromise
      .then(() => {
        observer.next(undefined);
        observer.complete();
      })
      .catch((err) => {
        captureException(err, {
          extra: { logic: 'refreshTokenObserver' },
        });
        // If we cannot refresh the session it means that the token is stale
        // or corrupted. In such case, api endpoint should remove the
        // authentication cookies. Assuming that's done, we should refresh the
        // page to make sure that outdated cookies won't be included in the
        // request again.
        window.location.reload();
      })
      .finally(() => {
        refreshPromise = null;
      });
  });
