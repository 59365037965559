import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum BILLING_ADDRESS {
  BILLING_PERSONAL = 'billing-address',
  BILLING_COMPANY = 'company-billing-address',
}

interface CheckoutState {
  deliveryStep: {
    shouldSubmitButtonShowLoader: boolean;
  };
  billingAddressStep: {
    selectedBillingAddressType: BILLING_ADDRESS;
  };
}

const initialState: CheckoutState = {
  deliveryStep: {
    shouldSubmitButtonShowLoader: false,
  },
  billingAddressStep: {
    selectedBillingAddressType: BILLING_ADDRESS.BILLING_PERSONAL,
  },
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setShouldSubmitButtonShowLoader: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.deliveryStep.shouldSubmitButtonShowLoader = action.payload;
    },
    setBillingAddressType: (state, action: PayloadAction<BILLING_ADDRESS>) => {
      state.billingAddressStep.selectedBillingAddressType = action.payload;
    },
  },
});

export const { setShouldSubmitButtonShowLoader, setBillingAddressType } =
  checkoutSlice.actions;

export default checkoutSlice.reducer;
