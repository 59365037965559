import { AUTH_ROLE } from '../contexts/AuthContext';
import { Session } from '../types';

export const selectAuthRole = (session: Session) => {
  if (!session) return AUTH_ROLE.UNAUTHENTICATED;

  const { userProfile } = session.data.attributes;

  if (userProfile.isAnonymous) {
    return AUTH_ROLE.ANONYMOUS_AUTHENTICATED;
  }

  if (!userProfile.isConfirmed) {
    return AUTH_ROLE.NOT_CONFIRMED;
  }

  return AUTH_ROLE.AUTHENTICATED;
};
