import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Add your custom modal types here like: "categories" | "product" | "cart"
type ModalTypes = 'categories';

interface ModalsState {
  opened: ModalTypes[];
}

const initialState: ModalsState = {
  opened: [],
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalTypes>) => {
      state.opened.push(action.payload);
    },
    closeModal: (state, action: PayloadAction<ModalTypes>) => {
      state.opened = state.opened.filter((modal) => modal !== action.payload);
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
