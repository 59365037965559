import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ProfileState {
  actionStatusData: {
    title: string;
    description: string;
  } | null;
}

const initialState: ProfileState = {
  actionStatusData: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileActionStatusData: (
      state,
      action: PayloadAction<{ title: string; description: string } | null>
    ) => {
      state.actionStatusData = action.payload;
    },
  },
});

export const { setProfileActionStatusData } = profileSlice.actions;

export default profileSlice.reducer;
