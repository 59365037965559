import { createContext } from 'react';

export const UtmContext = createContext<UtmContextType>({
  get utm(): never {
    throw new Error('UtmContext has been not provided');
  },
  get clearUtm(): never {
    throw new Error('UtmContext has been not provided');
  },
});

export type UtmObject = {
  [k in (typeof UTM_KEYS)[number]]?: string;
};
type UtmContextType = {
  utm: UtmObject | null;
  clearUtm: () => void;
};

export const UTM_KEYS = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_content',
] as const;
