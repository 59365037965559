import * as Types from '@/types/gqlCommon.common.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"common"}} as const;
export type CustomerShippingAddressQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomerShippingAddressQuery = { readonly __typename: 'Query', readonly customerShippingAddress?: { readonly __typename: 'CustomerShippingAddress', readonly id: string, readonly shippingAddress: { readonly __typename: 'ShippingAddress', readonly firstName?: string | null, readonly lastName?: string | null, readonly streetName?: string | null, readonly streetNumber: string, readonly postalCode: string, readonly city: string, readonly countryCode: string, readonly apartment?: string | null, readonly lat: number, readonly long: number, readonly floor?: string | null, readonly deliveryNotes?: string | null, readonly phoneNumber?: { readonly __typename: 'PhoneNumber', readonly nationalNumber: string, readonly countryCode: string } | null } } | null };


export const CustomerShippingAddressDocument = gql`
    query CustomerShippingAddress {
  customerShippingAddress {
    id
    shippingAddress {
      firstName
      lastName
      phoneNumber {
        nationalNumber
        countryCode
      }
      streetName
      streetNumber
      postalCode
      city
      countryCode
      apartment
      lat
      long
      floor
      deliveryNotes
    }
  }
}
    `;

/**
 * __useCustomerShippingAddressQuery__
 *
 * To run a query within a React component, call `useCustomerShippingAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerShippingAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerShippingAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerShippingAddressQuery(baseOptions?: Apollo.QueryHookOptions<CustomerShippingAddressQuery, CustomerShippingAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerShippingAddressQuery, CustomerShippingAddressQueryVariables>(CustomerShippingAddressDocument, options);
      }
export function useCustomerShippingAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerShippingAddressQuery, CustomerShippingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerShippingAddressQuery, CustomerShippingAddressQueryVariables>(CustomerShippingAddressDocument, options);
        }
export function useCustomerShippingAddressSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomerShippingAddressQuery, CustomerShippingAddressQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerShippingAddressQuery, CustomerShippingAddressQueryVariables>(CustomerShippingAddressDocument, options);
        }
export type CustomerShippingAddressQueryHookResult = ReturnType<typeof useCustomerShippingAddressQuery>;
export type CustomerShippingAddressLazyQueryHookResult = ReturnType<typeof useCustomerShippingAddressLazyQuery>;
export type CustomerShippingAddressSuspenseQueryHookResult = ReturnType<typeof useCustomerShippingAddressSuspenseQuery>;
export type CustomerShippingAddressQueryResult = Apollo.QueryResult<CustomerShippingAddressQuery, CustomerShippingAddressQueryVariables>;