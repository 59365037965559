import { createContext } from 'react';

import { CHECKOUT_STEP } from '@/modules/analytics/eventHandlers/types';

export const CheckoutContext = createContext<CheckoutContextType>({
  get latestReachedStep(): never {
    throw new Error('Missing CheckoutContext');
  },
});

type CheckoutContextType = {
  latestReachedStep: CHECKOUT_STEP;
};
