/** Patterns for routes that are available only for authenticated users */
export const authenticatedRoutePatterns = [
  /\/?your-account\/?.*?/,
  /\/?order-details\/?.*?/,
  /\/?checkout\/?.*?/,
  /\/?favourites\/?.*/,
  /\/?profile\/?.*/,
];

//* * Config for routing paths */
export const APP_ROUTES = {
  categories: '/categories',
  orderHistory: '/profile/order-history',
  orderDetails: 'order-details',
  aboutUsMapAnchor: 'map-cta',
  aboutUs: '/about-us',
  cart: '/cart',
  // cart sidebar is opened on mobile devices on the homepage
  cartMobile: '/?cartSidebar=open',
  checkoutDelivery: '/checkout',
  checkoutBillingAddress: '/checkout/billing-address',
  checkoutPayment: '/checkout/payment',
  promotions: (l0PromotionKey: string, l1PromotionKey?: string) =>
    `/promotions/${[l0PromotionKey, l1PromotionKey].filter(Boolean).join('/')}`,
};
