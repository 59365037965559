import { mergeAndCompare } from 'merge-anything';

/**
 * tldr: Use it when you're updating the query result synchronously using
 * `.updateQuery` method.
 *
 * It deeply updates object in a way: `target` <- `source`.
 *
 * It's built upon [merge-anything#mergeAndCompare](https://www.npmjs.com/package/merge-anything)
 *
 * The only difference is that `null` or `undefined` from source, won't
 * override the target property.
 */
export const updateDeep = <T extends Record<string, unknown>>(
  target: T,
  source: DeepPartial<T>
): T => mergeAndCompare(compare, target, source as T) as unknown as T;

const compare = (prevVal: unknown, newVal: unknown) => {
  if (prevVal === undefined) return newVal;
  return newVal === undefined || newVal === null ? prevVal : newVal;
};

export type DeepPartial<T> = T extends (...args: unknown[]) => unknown
  ? T
  : T extends Array<infer U>
    ? Array<U>
    : T extends Record<string, unknown>
      ? DeepPartialObject<T>
      : T | undefined;

export type DeepPartialObject<T> = { [P in keyof T]?: DeepPartial<T[P]> };
