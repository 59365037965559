import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface CategoriesState {
  selectedSlot: {
    dateFrom: string;
    dateTo: string;
  } | null;
}

const initialState: CategoriesState = {
  selectedSlot: null,
};

export const slotsSlice = createSlice({
  name: 'slots',
  initialState,
  reducers: {
    selectSlot: (
      state,
      action: PayloadAction<{
        dateFrom: string;
        dateTo: string;
      } | null>
    ) => {
      state.selectedSlot = action.payload;
    },
  },
});

export const { selectSlot } = slotsSlice.actions;

export default slotsSlice.reducer;
