import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-hot-toast';

import {
  CustomerShippingAddressQuery,
  useCustomerShippingAddressQuery,
} from '@/core/graphql/queries/CustomerShippingAddress/CustomerShippingAddress.common.generated';
import { DEFAULT_ADDRESS_COUNTRY_CODE } from '@/core/types';
import { ShippingAddressInput } from '@/types/gqlCommon.delio.generated';
import { updateDeep } from '@lib/api/update-deep';

import { useCreateCustomerShippingAddressMutation } from '../graphql/mutations/CreateCustomerShippingAddress/CreateCustomerShippingAddress.common.generated';
import { useUpdateCustomerShippingAddressMutation } from '../graphql/mutations/UpdateCustomerShippingAddress/UpdateCustomerShippingAddress.common.generated';

export const useUpdateOrCreateCustomerShippingAddress = () => {
  const { t } = useTranslation('common');

  const customerShippingAddressResult = useCustomerShippingAddressQuery({
    skip: true,
  });
  const [updateCustomerShippingAddress] =
    useUpdateCustomerShippingAddressMutation({
      onError: (err: ApolloError) => {
        captureException(err);
        toast.error(t('Something went wrong, please try again later'));
      },
    });
  const [createCustomerShippingAddress] =
    useCreateCustomerShippingAddressMutation({
      onError: (err: ApolloError) => {
        captureException(err);
        toast.error(t('Something went wrong, please try again later'));
      },
    });

  return async (
    newAddress: ShippingAddressInput,
    prevShippingAddress?: CustomerShippingAddressQuery['customerShippingAddress']
  ) => {
    if (prevShippingAddress) {
      await updateCustomerShippingAddress({
        variables: {
          updateCustomerShippingAddressId: prevShippingAddress.id,
          shippingAddress: {
            ...prevShippingAddress.shippingAddress,
            ...newAddress,
            countryCode: DEFAULT_ADDRESS_COUNTRY_CODE,
          },
        },
      });
    } else {
      const createCustomerShippingAddressResult =
        await createCustomerShippingAddress({
          variables: {
            shippingAddress: newAddress,
          },
        });

      customerShippingAddressResult.updateQuery((prev) =>
        updateDeep(prev, {
          customerShippingAddress:
            createCustomerShippingAddressResult.data
              ?.createCustomerShippingAddress,
        })
      );
    }
  };
};
