import { createContext } from 'use-context-selector';

import { FirebaseAuthClient } from '@lib/firebase-auth-client/firebase-auth-client';

import {
  CreateSession,
  CreateSessionState,
  DestroySession,
  DestroySessionState,
} from '../hooks/useSessionManagement';
import { ClientAuthorizationData } from '../types';

export const AuthContext = createContext<AuthContextType>({
  get firebaseAuthClient(): never {
    throw missingAuthContextError;
  },
  get createSessionState(): never {
    throw missingAuthContextError;
  },
  get createSession(): never {
    throw missingAuthContextError;
  },
  get destroySessionState(): never {
    throw missingAuthContextError;
  },
  get destroySession(): never {
    throw missingAuthContextError;
  },
  get authRole(): never {
    throw missingAuthContextError;
  },
  get confirmUser(): never {
    throw missingAuthContextError;
  },
});

const missingAuthContextError = Error('AuthContext is not provided');

export interface AuthContextType {
  firebaseAuthClient: FirebaseAuthClient;
  createSessionState: CreateSessionState;
  createSession: CreateSession;
  destroySessionState: DestroySessionState;
  destroySession: DestroySession;
  authRole: AUTH_ROLE;
  confirmUser: (
    userDetails: Omit<ClientAuthorizationData, 'sessionSource'>
  ) => Promise<Omit<ClientAuthorizationData, 'sessionSource'>>;
}

export enum AUTH_ROLE {
  /** The user just visits the page, they don't have any settings in the app. */
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  /**
   * The user has not signed in, but created a temporary account for them,
   * so they can keep some persistent settings after the page refresh.
   */
  ANONYMOUS_AUTHENTICATED = 'ANONYMOUS_AUTHENTICATED',
  /**
   * User has signed in.
   */
  AUTHENTICATED = 'AUTHENTICATED',

  NOT_CONFIRMED = 'NOT_CONFIRMED',
}
