import type { SessionDocument } from '../types';

/**
 * It refreshes the ID token in the cookie.
 */
export const refreshSession = async () => {
  const axios = await getAxios();
  return axios.put<SessionDocument>('/api/session/');
};

/**
 * It creates a session using a `refreshToken` from the Firebase sign in method.
 */
export const createSession = async (refreshToken: string) => {
  const axios = await getAxios();
  return axios.post<SessionDocument>('/api/session/', {
    refreshToken,
  });
};

/**
 * It removes the session in the server storage, as well as unset the
 * session cookies.
 */
export const destroySession = async () => {
  const axios = await getAxios();
  return axios.delete('/api/session/');
};

const getAxios = async () => {
  const { default: axios } = await import('axios');
  return axios;
};
