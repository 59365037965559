import { Session } from '@/modules/auth/types';

export const loadFreshdesk = (session: Session) => {
  if (
    window?.fcWidget &&
    !window.fcWidget?.isInitialized() &&
    session?.data.id
  ) {
    window.fcWidget.init({
      externalId: String(session.data.id),
      token: '703c17cb-8593-4011-8b39-70c05a1f9b47',
      host: 'https://lite-assist.freshchat.com',
      tags: ['delio'],
      widgetUuid: '7f9d3aae-8523-4d69-a703-3da00d1b23c5',
    });
  }
};
