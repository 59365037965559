import { InMemoryCache } from '@apollo/client';

import { CategoryQuery } from '@/modules/categories/queries/Category.delio.sdk.generated';

export const createMemoryCache = () =>
  new InMemoryCache({
    typePolicies: {
      Product: {
        keyFields: ['id', 'isPromotedProduct'],
        merge: (...[, incoming]: unknown[]) => incoming,
        fields: {
          bundleItems: {
            // eslint-disable-next-line default-param-last
            merge(existing = [], incoming) {
              if (incoming === null) {
                return existing;
              }
              return incoming;
            },
          },
        },
      },
      ProductAttributes: {
        merge: (
          existing: Record<string, unknown> | undefined,
          incoming: Record<string, unknown> | undefined
        ) => ({ ...existing, ...incoming }),
      },
      Category: {
        fields: {
          children: {
            merge: (
              existing: NonNullable<CategoryQuery['category']>['children'] = [],
              incoming: NonNullable<CategoryQuery['category']>['children'] = []
            ) => [
              ...existing,
              ...incoming.filter((i) => !existing.find((e) => e.id === i.id)),
            ],
          },
        },
      },
    },
  });
