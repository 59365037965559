import { createClientLogger } from './clientSideConfig';

const createLogger = () => {
  if (typeof window === 'undefined') {
    const { createServerLogger } =
      require('./serverLogger') as typeof import('./serverLogger');
    return createServerLogger();
  }

  return createClientLogger();
};

export const logger =
  process.env.NODE_ENV !== 'test'
    ? createLogger()
    : {
        /* eslint-disable no-console */
        fatal: console.error,
        error: console.error,
        // Do not log warn in tests to keep the console view clean.
        warn: () => null,
        info: console.log,
        debug: console.log,
        trace: console.log,
        /* eslint-enable no-console */
      };
