import { getApps, initializeApp } from 'firebase/app';
import type { Auth } from 'firebase/auth';
import {
  browserPopupRedirectResolver,
  initializeAuth,
  inMemoryPersistence,
} from 'firebase/auth';

import { signInAnonymously, signInWithCustomToken } from './methods';

export const createFirebaseAuthClient = (config: FirebaseAuthClientConfig) =>
  typeof window === 'undefined'
    ? createFirebaseClientOnServer()
    : createFirebaseClientOnBrowser(config);

const createFirebaseClientOnBrowser = (config: FirebaseAuthClientConfig) => {
  const auth = createGetAuth(config);

  return {
    signInWithCustomToken: (customToken: string) =>
      signInWithCustomToken(auth, customToken),
    signInAnonymously: () => signInAnonymously(auth),
  };
};

const createFirebaseClientOnServer = (): FirebaseAuthClient => {
  const clientSideOnlyMethodError = new Error(
    'This method is not meant be used on the server-side'
  );

  return {
    signInWithCustomToken(): never {
      throw clientSideOnlyMethodError;
    },
    signInAnonymously(): never {
      throw clientSideOnlyMethodError;
    },
  };
};

const createGetAuth = (config: FirebaseAuthClientConfig) => {
  let auth: Auth | null = null;
  // Initialize app only if it does not exist already
  const app =
    getApps()[0] ||
    initializeApp({
      ...config,
      appId: '1:742422486590:web:a5690109b9d773b584fb6fk',
    });

  if (auth) return auth;

  auth = initializeAuth(app, {
    // Do not store tokens in `localStorage`, we use custom session cookie
    persistence: inMemoryPersistence,
    // `signInWithPopup` is going to crash without this option
    popupRedirectResolver: browserPopupRedirectResolver,
  });

  return auth;
};

export interface FirebaseAuthClientConfig {
  apiKey: string;
  authDomain: string;
}

export type FirebaseAuthClient = ReturnType<
  typeof createFirebaseClientOnBrowser
>;
