import { useCallback } from 'react';

import { logger } from '@/config/logger';
import {
  useCurrentCartLazyQuery,
  useCurrentCartQuery,
} from '@/core/graphql/queries/CurrentCart/CurrentCart.delio.generated';
import { useCustomerShippingAddressLazyQuery } from '@/core/graphql/queries/CustomerShippingAddress/CustomerShippingAddress.common.generated';
import { useUpdateOrCreateCustomerShippingAddress } from '@/core/hooks/useUpdateOrCreateCustomerShippingAddress';
import { updateDeep } from '@lib/api/update-deep';

import { useMergeAnonymousCustomerMutation } from '../graphql/mutations/MergeAnonymousCustomer/MergeAnonymousCustomer.common.generated';

export const useMergeAnonymousCustomer = () => {
  const [mergeAnonymousCustomer] = useMergeAnonymousCustomerMutation();

  const updateOrCreateCustomerShippingAddress =
    useUpdateOrCreateCustomerShippingAddress();
  const [getCurrentCart] = useCurrentCartLazyQuery();
  const [getCustomerShippingAddress] = useCustomerShippingAddressLazyQuery();
  const currentCartQueryResult = useCurrentCartQuery({ skip: true });

  return useCallback(
    async (firebaseAnonymousUserId: string) => {
      logger.info('Merging the anonymous user with the authenticated user...');
      await mergeAnonymousCustomer({
        variables: {
          firebaseAnonymousUserId,
        },
      });
      logger.info('The user has been merged');

      logger.info('Refetching the active cart...');
      const currentCartResult = await getCurrentCart({
        fetchPolicy: 'network-only',
      });
      const currentCartShippingAddress =
        currentCartResult.data?.currentCart.shippingAddress;
      const prevCustomerShippingAddress = (
        await getCustomerShippingAddress({ fetchPolicy: 'network-only' })
      ).data?.customerShippingAddress;
      logger.info('The active cart has been refetched');

      if (!currentCartShippingAddress) {
        throw new Error('Merged currentCart does not have a shipping address');
      }

      logger.info('Replacing customer address...');

      await updateOrCreateCustomerShippingAddress(
        currentCartShippingAddress,
        prevCustomerShippingAddress
      );

      logger.info('Address has been replaced');

      // we need to update cart cache after merge customer
      currentCartQueryResult.updateQuery((prev) =>
        updateDeep(prev, {
          currentCart: currentCartResult.data?.currentCart,
        })
      );
    },
    [
      mergeAnonymousCustomer,
      getCurrentCart,
      getCustomerShippingAddress,
      updateOrCreateCustomerShippingAddress,
      currentCartQueryResult,
    ]
  );
};
