import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { APP_ROUTES } from '@/config/routing';
import { CHECKOUT_STEP } from '@/modules/analytics/eventHandlers/types';
import { FCC } from '@/types/common';

import { CheckoutContext } from '../context/CheckoutContext';

export const CheckoutProvider: FCC = ({ children }) => {
  const [latestReachedStep, setLatestReachedStep] = useState<CHECKOUT_STEP>(
    CHECKOUT_STEP.DELIVERY
  );
  const { pathname } = useRouter();

  useEffect(() => {
    const currentStep = getCurrentStep(pathname);

    if (!currentStep) {
      return setLatestReachedStep(CHECKOUT_STEP.DELIVERY);
    }

    setLatestReachedStep((prevStep) =>
      prevStep > currentStep ? prevStep : currentStep
    );
  }, [pathname]);

  const checkoutContextProps = useMemo(
    () => ({ latestReachedStep }),
    [latestReachedStep]
  );

  return (
    <CheckoutContext.Provider value={checkoutContextProps}>
      {children}
    </CheckoutContext.Provider>
  );
};

const getCurrentStep = (pathname: string) => {
  switch (pathname) {
    case APP_ROUTES.checkoutBillingAddress: {
      return CHECKOUT_STEP.BILLING_ADDRESS;
    }
    case '/checkout': {
      return CHECKOUT_STEP.DELIVERY;
    }
    case APP_ROUTES.checkoutPayment: {
      return CHECKOUT_STEP.PAYMENT;
    }
    default: {
      return null;
    }
  }
};
