import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface CategoriesState {
  // undefined value indicate that the user didn't select any category yet
  // and in the navigation we can use url category parameter as selected item
  selectedL0Category: string | null | undefined;
}

const initialState: CategoriesState = {
  selectedL0Category: undefined,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    selectL0CategoryKey: (state, action: PayloadAction<string | null>) => {
      state.selectedL0Category = action.payload;
    },
  },
});

export const { selectL0CategoryKey } = categoriesSlice.actions;

export default categoriesSlice.reducer;
