/**
 * We need it avoid duplicated images when JS is disabled.
 */
export const NoJsImageStyle = () => (
  <noscript>
    <style>{noJsStyles}</style>
  </noscript>
);

/**
 * CSS class responsible for hiding the element if JS is not available in
 * the browser. That's because we need a separate image for that case and we
 * need to hide the original one to avoid duplication.
 */
export const NO_JS_CLASS_NAME = 'hide-when-js-is-not-available';

/**
 * Use this CSS inside the `<noscript>`. Then, apply the `.no-js` class to the
 * <img /> outside the `<noscript>`.
 */
const noJsStyles = `.${NO_JS_CLASS_NAME} { display: none !important; }`;
