import { createPagesList, ELLIPSIS } from './create-pages-list';
import {
  FIRST_PAGE,
  getCurrentPage,
  PAGE_SEARCH_PARAM_NAME,
} from './get-current-page';

export const createPaginationAsPathsFactory =
  (origin: string) => (config: CreatePaginationAsPathsConfig) =>
    createPaginationAsPaths(origin, config);

/**
 * It generates pagination links via `asPath` (`pathname` + `search` + `hash`)
 *
 * It preserves `pathname`, `search` and `hash`, that have been already
 * included in the base `asPath`, and attaches a search param with the page
 * number to the base `asPath`.
 */
const createPaginationAsPaths: CreatePaginationAsPaths = (origin, config) => {
  const baseUrl = createUrl(`${origin}${config.asPath}`);

  const currentPage = getCurrentPage({
    [PAGE_SEARCH_PARAM_NAME]:
      baseUrl.searchParams.get(PAGE_SEARCH_PARAM_NAME) || '',
  });

  const maxPageFromTotalItems = Math.ceil(
    config.totalItems / config.itemsPerPage
  );

  const maxPage =
    maxPageFromTotalItems < FIRST_PAGE ? FIRST_PAGE : maxPageFromTotalItems;

  const nextPageUrl = createPageUrl(baseUrl.href, { page: currentPage + 1 });

  const prevPageUrl = createPageUrl(baseUrl.href, { page: currentPage - 1 });

  const pagesList = createPagesList(currentPage, maxPage, config.isMobile).map(
    (page) => {
      if (page === ELLIPSIS) {
        return {
          page: ELLIPSIS,
          asPath: null,
          isCurrent: false as const,
        };
      }

      const pageUrl = createPageUrl(baseUrl.href, { page });

      return {
        page,
        asPath: pageUrl && getAsPath(pageUrl),
        isCurrent: page === currentPage,
      };
    }
  );

  return {
    nextPageAsPath: currentPage >= maxPage ? null : getAsPath(nextPageUrl),
    prevPageAsPath: currentPage <= FIRST_PAGE ? null : getAsPath(prevPageUrl),
    pagesList,
  };
};

interface CreatePaginationAsPaths {
  (
    origin: string,
    config: CreatePaginationAsPathsConfig
  ): {
    nextPageAsPath: string | null;
    prevPageAsPath: string | null;
    pagesList: Readonly<
      | {
          page: number | string;
          asPath: string | null;
          isCurrent: boolean;
        }
      | {
          page: typeof ELLIPSIS;
          asPath: null;
          isCurrent: false;
        }
    >[];
  };
}

type CreatePaginationAsPathsConfig = {
  /** Total available items for the given data query (all pages summed up) */
  totalItems: number;
  /** The number of items available on each page */
  itemsPerPage: number;
  /**
   * The path (including the query) shown in the browser without the configured
   * basePath or locale
   */
  asPath: string;
  isMobile: boolean;
};

const getAsPath = (urlInstance: URL) =>
  `${urlInstance.pathname}${urlInstance.search}${urlInstance.hash}`;

const createPageUrl = (
  href: string,
  config: {
    page: number | string;
  }
) => {
  const pageUrl = createUrl(href);
  pageUrl.searchParams.set(PAGE_SEARCH_PARAM_NAME, String(config.page));
  return pageUrl;
};

/**
 * Returns cross-environment URL instance
 */
const createUrl = (href: string): URL => {
  if (typeof window === 'undefined') {
    const urlModule = require('url') as typeof import('url');
    return new urlModule.URL(href);
  }

  return new window.URL(href);
};
