import { NormalizedCacheObject } from '@apollo/client';
import { ErrorHandler } from '@apollo/client/link/error';
import pino from 'pino';

import {
  API_VERSION_HEADERS,
  CSRF_PROTECTION_HEADER_NAME,
} from '@/config/consts';
import { createApiClient } from '@lib/api/create-api-client';
import { publicEnv } from '@lib/env/public-env-vars';
import { createPaginationAsPathsFactory } from '@lib/pagination/create-pagination-as-paths';

import { createMemoryCache } from './cache';
import { clientSideErrorHandler } from './clientSideErrorHandler';

/**
 * To use in the `ApiProvider` in the browser
 */
export const createApiGatewayClient = (state: NormalizedCacheObject | null) =>
  createApiClient({
    uri: publicEnv.NEXT_PUBLIC_API_GATEWAY_URI,
    ssrState: state,
    errorHandler: clientSideErrorHandler,
    headers: {
      ...API_VERSION_HEADERS,
      [CSRF_PROTECTION_HEADER_NAME]: '',
    },
    cache: createMemoryCache(),
  });

/**
 * It generates pagination links via `asPath` (`pathname` + `search` + `hash`)
 *
 * It preserves `pathname`, `search` and `hash`, that have been already
 * included in the base `asPath`, and attaches a search param with the page
 * number to the base `asPath`.
 */
export const createPaginationAsPaths = createPaginationAsPathsFactory(
  publicEnv.NEXT_PUBLIC_WEBSITE_URL
);

export const createClientLogger = () => {
  const clientLogger = pino({
    level: publicEnv.NEXT_PUBLIC_LOG_LEVEL,
    browser: {
      serialize: true,
    },
  });

  return clientLogger;
};

export type ApiGateWayClientOptions = {
  errorHandler?: ErrorHandler;
};
