import * as Types from '@/types/gqlCommon.common.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"common"}} as const;
export type MergeAnonymousCustomerMutationVariables = Types.Exact<{
  firebaseAnonymousUserId: Types.Scalars['String']['input'];
}>;


export type MergeAnonymousCustomerMutation = { readonly __typename: 'Mutation', readonly mergeAnonymousCustomer: boolean };


export const MergeAnonymousCustomerDocument = gql`
    mutation MergeAnonymousCustomer($firebaseAnonymousUserId: String!) {
  mergeAnonymousCustomer(firebaseAnonymousUserId: $firebaseAnonymousUserId)
}
    `;
export type MergeAnonymousCustomerMutationFn = Apollo.MutationFunction<MergeAnonymousCustomerMutation, MergeAnonymousCustomerMutationVariables>;

/**
 * __useMergeAnonymousCustomerMutation__
 *
 * To run a mutation, you first call `useMergeAnonymousCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeAnonymousCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeAnonymousCustomerMutation, { data, loading, error }] = useMergeAnonymousCustomerMutation({
 *   variables: {
 *      firebaseAnonymousUserId: // value for 'firebaseAnonymousUserId'
 *   },
 * });
 */
export function useMergeAnonymousCustomerMutation(baseOptions?: Apollo.MutationHookOptions<MergeAnonymousCustomerMutation, MergeAnonymousCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeAnonymousCustomerMutation, MergeAnonymousCustomerMutationVariables>(MergeAnonymousCustomerDocument, options);
      }
export type MergeAnonymousCustomerMutationHookResult = ReturnType<typeof useMergeAnonymousCustomerMutation>;
export type MergeAnonymousCustomerMutationResult = Apollo.MutationResult<MergeAnonymousCustomerMutation>;
export type MergeAnonymousCustomerMutationOptions = Apollo.BaseMutationOptions<MergeAnonymousCustomerMutation, MergeAnonymousCustomerMutationVariables>;